import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

/* MUI */
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, useTheme } from '@mui/material/styles';
import { Clear } from '@mui/icons-material';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: '0 0.5rem',
  border: `solid ${theme.spacing(0.0625)} #000`,
  borderRadius: theme.spacing(0.25),
  height: '2rem',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: '2rem',
  },
  flexGrow: 1,
  fontSize: '0.75rem',
  fontFamily: 'Nunito',
  '@supports (-webkit-overflow-scrolling: touch)': {
    fontSize: '1rem',
  },
  '@media screen and (min-width: 2000px)': {
    fontSize: '1rem',
  },
}));

const searchProductsBarDefaultPropSx = {};

const SearchProductsBar = ({ sx = searchProductsBarDefaultPropSx }) => {
  const theme = useTheme();
  const router = useRouter();
  const [search, setSearch] = useState(router.query['search-query'] || '');

  const handleSearchInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  const clearSearchInput = () => {
    if (router.query['search-query']) {
      setSearch('');
      router.push({ ...router, query: { ...router.query, 'search-query': '' } });
    } else {
      setSearch('');
    }
  };

  const handleOnIconClick = () => {
    if (!search) return;
    router.push({ ...router, query: { ...router.query, 'search-query': search } });
  };

  return (
    <StyledInputBase
      id="store-search-products-input"
      value={search}
      onKeyDown={(e) => e.key === 'Enter' && handleOnIconClick()}
      onChange={handleSearchInputChange}
      inputProps={{ 'aria-label': 'search', 'data-testid': 'search-product-bar__input' }}
      placeholder="Search by product name and description"
      sx={{
        ...sx,
      }}
      endAdornment={
        <>
          {search && (
            <Clear
              data-testid="store-search-products-btn-clear"
              onClick={clearSearchInput}
              sx={{
                width: '1.5rem',
                height: '1.5rem',
                cursor: 'pointer',
                marginRight: 0.5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.spacing(1.125),
                },
              }}
            />
          )}
          <SearchIcon
            data-testid="store-search-products-btn-search"
            onClick={handleOnIconClick}
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              cursor: 'pointer',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(1.125),
              },
            }}
          />
        </>
      }
    />
  );
};

export default SearchProductsBar;

SearchProductsBar.propTypes = {
  sx: PropTypes.shape({}),
};
