import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const Option = ({ option, index, values, handleCheckboxChange, showOptionCount, dataTestId }) => {
  return (
    <ListItem key={option.value} disablePadding>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            data-testid={`multi-select-with-search-${dataTestId}-opt-${index}`}
            sx={{ color: 'black' }}
          />
        }
        sx={{
          ml: 0,
          mr: 0,
          width: '100%',
        }}
        label={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                className="notranslate"
                data-testid={`multi-select-with-search-${dataTestId}-opt-${index}-lbl`}
              >
                {option.label}
              </Typography>
              {option.count > 0 && showOptionCount && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black',
                    fontSize: '0.65rem',
                    backgroundColor: '#F1F5F4',
                    padding: '0.25rem',
                    borderRadius: '50%',
                    textAlign: 'center',
                    height: '30px',
                    width: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 0.5,
                  }}
                  data-testid={`multi-select-with-search-${dataTestId}-opt-${index}-count`}
                >
                  {option.count}
                </Typography>
              )}
            </Box>

            {option.tooltipMsg && (
              <Tooltip
                title={option.tooltipMsg}
                arrow
                data-testid={`multi-select-with-search-${dataTestId}-opt-${index}-tooltip`}
              >
                <IconButton size="small" color="primary">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
      />
    </ListItem>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number,
    tooltipMsg: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  showOptionCount: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

const MultiSelectWithSearch = ({
  title,
  options,
  showSearchInput = false,
  clear,
  dataTestId,
  values,
  setValues,
  titleTooltipMsg = '',
  searchInputPlaceholder = '',
  showFilterCounter = false,
  hasSearch = false,
  defaultExpanded = false,
  sort = false,
  showOptionCount = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isExpandedOptions, setIsExpandedOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filterHeight = hasSearch ? '198px' : '240px';

  const handleCheckboxChange = (id) => {
    const newSelected = values.includes(id) ? values.filter((item) => item !== id) : [...values, id];
    setValues(newSelected);
  };

  const handleClear = () => {
    clear();
  };

  const filteredOptions = useMemo(() => {
    let result = options;
    if (showSearchInput) {
      result = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (sort) {
      result = result.sort((a, b) => b.count - a.count);
    }
    return result;
  }, [options, searchTerm, showSearchInput, sort]);

  const displayedOptions = useMemo(() => {
    return isExpandedOptions ? filteredOptions : filteredOptions.slice(0, 5);
  }, [filteredOptions, isExpandedOptions]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-testid={`multi-select-with-search-${dataTestId}-container`}
    >
      <input
        type="hidden"
        value={values || ''}
        data-testid={`multi-select-with-search-${dataTestId}-hidden-input`}
        aria-hidden="true"
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid #ccc', padding: 0.5, backgroundColor: '#F9FAFB', width: '100%' }}
      >
        <Box sx={{ padding: '0 0.5rem' }}>
          {titleTooltipMsg && (
            <Tooltip title={titleTooltipMsg} arrow data-testid={`multi-select-with-search-${dataTestId}-title-tooltip`}>
              <IconButton size="small" color="primary">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            variant="h7"
            sx={{ fontWeight: 'bold' }}
            data-testid={`multi-select-with-search-${dataTestId}-title`}
          >
            {title}
          </Typography>
          {showFilterCounter && values.length > 0 && (
            <Typography
              variant="body2"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#497262',
                color: 'white',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                ml: 0.5,
              }}
              data-testid={`multi-select-with-search-${dataTestId}-filter-counter`}
            >
              {values.length}
            </Typography>
          )}
        </Box>

        <div>
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            data-testid={`multi-select-with-search-${dataTestId}-expand`}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </Box>
      <Collapse
        in={isExpanded}
        sx={{
          padding: '0 0.5rem',
          backgroundColor: 'white',
        }}
      >
        {hasSearch && (
          <TextField
            placeholder={searchInputPlaceholder}
            size="small"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            data-testid={`multi-select-with-search-${dataTestId}-search-input`}
            sx={{ mr: 2, mt: 0.5 }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        {isExpanded && (
          <Box data-testid={`multi-select-with-search-${dataTestId}-container-expanded`}>
            <Box
              sx={{
                maxHeight: isExpandedOptions ? filterHeight : 'auto',
                overflowY: isExpandedOptions ? 'auto' : 'visible',
                overflowX: 'hidden',
              }}
            >
              <List>
                {displayedOptions.map((option, index) => (
                  <Option
                    key={`option-${option.value}`}
                    option={option}
                    index={index}
                    values={values}
                    handleCheckboxChange={handleCheckboxChange}
                    showOptionCount={showOptionCount}
                    dataTestId={dataTestId}
                  />
                ))}
              </List>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ padding: '0.5rem 0.5rem 1rem 0.5rem' }}>
              {filteredOptions.length > 5 && (
                <Typography
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    color: 'black',
                    textDecoration: 'underline',
                    width: '100%',
                  }}
                  onClick={() => setIsExpandedOptions((prev) => !prev)}
                  data-testid={`multi-select-with-search-${dataTestId}-label-${
                    isExpandedOptions ? 'show-less' : 'show-more'
                  }`}
                >
                  {isExpandedOptions ? 'Show less' : 'Show all'}
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  color: 'black',
                  textDecoration: 'underline',
                  textAlign: 'end',
                  width: '100%',
                }}
                onClick={handleClear}
                data-testid={`multi-select-with-search-${dataTestId}-label-clear`}
              >
                Clear
              </Typography>
            </Box>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default MultiSelectWithSearch;

MultiSelectWithSearch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      tooltipMsg: PropTypes.string,
    })
  ).isRequired,
  showSearchInput: PropTypes.bool,
  searchInputPlaceholder: PropTypes.string,
  setValues: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  titleTooltipMsg: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  showFilterCounter: PropTypes.bool,
  hasSearch: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  sort: PropTypes.bool,
  showOptionCount: PropTypes.bool,
};
