import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const ScrollToTopButton = ({ isTopVisible }) => {
  if (isTopVisible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '2rem',
        zIndex: 1,
      }}
    >
      <IconButton
        aria-label="Scroll to top"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        data-testid="scroll-to-top-button"
        size="large"
        sx={{
          backgroundColor: '#D5D5D5',
          boxShadow: 5,
          ':hover': { backgroundColor: '#E3E3E3' },
        }}
      >
        <KeyboardDoubleArrowUpIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

ScrollToTopButton.propTypes = {
  isTopVisible: PropTypes.bool.isRequired,
};

export default ScrollToTopButton;
