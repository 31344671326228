import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Collapse, IconButton, Slider, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const RangeFilter = ({ title, min, max, value, setValue, clear, dataTestId, titleTooltipMsg = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleClear = () => {
    clear();
  };

  return (
    <Box sx={{ width: '300px' }} data-testid={`range-filter-${dataTestId}-container`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid #ccc', padding: 0.5, backgroundColor: '#F9FAFB', width: '100%' }}
      >
        <Box sx={{ padding: '0 0.5rem' }}>
          {titleTooltipMsg && (
            <Tooltip title={titleTooltipMsg} arrow data-testid={`range-filter-${dataTestId}-title-tooltip`}>
              <IconButton size="small" color="primary">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="h7" sx={{ fontWeight: 'bold' }} data-testid={`range-filter-${dataTestId}-title`}>
            {title}
          </Typography>
        </Box>

        <IconButton onClick={() => setIsExpanded(!isExpanded)} data-testid={`range-filter-${dataTestId}-expand`}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={isExpanded} sx={{ padding: '0.5rem 1rem', backgroundColor: 'white' }}>
        <Box sx={{ px: 2 }}>
          <Slider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            data-testid={`range-filter-${dataTestId}-slider`}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">Min: {value[0]}</Typography>
            <Typography variant="body2">Max: {value[1]}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                color: 'black',
                textDecoration: 'underline',
              }}
              onClick={handleClear}
              data-testid={`range-filter-${dataTestId}-label-clear`}
            >
              Clear
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

RangeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  setValue: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  titleTooltipMsg: PropTypes.string,
};

export default RangeFilter;
