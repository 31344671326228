import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import SingleSelectWithSearch from 'components/common/SingleSelectWithSearch';
import MultiSelectWithSearch from 'components/common/MultiSelectWithSearch';

const FILTER_TYPES = {
  SINGLE: 'single',
  MULTI: 'multi',
};

const VirgoListingFilters = ({ filters }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '300px',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          mb: 2,
        },
      }}
    >
      {filters.map((filter) => {
        const commonProps = {
          key: filter.id,
          title: filter.title,
          options: filter.options,
          showSearchInput: filter.showSearch,
          clear: filter.onClear,
          dataTestId: filter.id,
          titleTooltipMsg: filter.tooltip,
          searchInputPlaceholder: filter.searchPlaceholder,
        };

        switch (filter.type) {
          case FILTER_TYPES.SINGLE:
            return (
              <SingleSelectWithSearch {...commonProps} value={filter.value} setValue={filter.onChange} showCounter />
            );
          case FILTER_TYPES.MULTI:
            return (
              <MultiSelectWithSearch
                {...commonProps}
                values={filter.values}
                setValues={filter.onChange}
                showCounter={filter.showCounter}
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};

VirgoListingFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(FILTER_TYPES)).isRequired,
      title: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          count: PropTypes.number,
          tooltipMsg: PropTypes.string,
        })
      ).isRequired,
      showSearch: PropTypes.bool,
      onClear: PropTypes.func.isRequired,
      tooltip: PropTypes.string,
      searchPlaceholder: PropTypes.string,
      // For single select
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      // For multi select
      values: PropTypes.arrayOf(PropTypes.string),
      showCounter: PropTypes.bool,
    })
  ).isRequired,
};

export default VirgoListingFilters;
