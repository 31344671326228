export const FILTER_TYPES = {
  SINGLE_SELECT: 'singleSelect',
  MULTI_SELECT: 'multiSelect',
  RANGE: 'range',
  TOGGLE: 'toggle',
};

export const FILTER_MAP = {
  productIsOnOffer: { filterType: FILTER_TYPES.TOGGLE },
  productCategoryName: { filterType: FILTER_TYPES.SINGLE_SELECT },
  productCategorySlug: { filterType: FILTER_TYPES.MULTI_SELECT },
  flairs: { filterType: FILTER_TYPES.MULTI_SELECT },
  productWholesalerName: { filterType: FILTER_TYPES.MULTI_SELECT },
  productWholesalerVisible: { filterType: FILTER_TYPES.TOGGLE },
  productWholesalerCountryId: { filterType: FILTER_TYPES.MULTI_SELECT },
  productWholesalerBrandValues: { filterType: FILTER_TYPES.MULTI_SELECT },
  productWholesalerCountryCode: { filterType: FILTER_TYPES.MULTI_SELECT },
  leadTime: { filterType: FILTER_TYPES.MULTI_SELECT },
  priceRange: { filterType: FILTER_TYPES.RANGE },
  productWholesalerStoreMinOrderAmountGbp: { filterType: FILTER_TYPES.SINGLE_SELECT },
  productCollectionName: { filterType: FILTER_TYPES.SINGLE_SELECT },
};

export const ALLOWED_FILTERS_IN_STORE_PAGE = ['productCategoryName', 'flairs', 'productCollectionName'];
