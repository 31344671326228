import PropTypes from 'prop-types';
import React from 'react';
import { Box, Switch, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const ToggleFilter = ({ title, value, setValue, dataTestId, titleTooltipMsg = '' }) => {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: '1px solid #ccc',
        padding: 0.5,
        backgroundColor: '#F9FAFB',
      }}
      data-testid={`toggle-filter-${dataTestId}-container`}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" sx={{ padding: '0 0.5rem' }}>
          {titleTooltipMsg && (
            <Tooltip title={titleTooltipMsg} arrow data-testid={`toggle-filter-${dataTestId}-title-tooltip`}>
              <IconButton size="small" color="primary">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="h7" sx={{ fontWeight: 'bold' }} data-testid={`toggle-filter-${dataTestId}-title`}>
            {title}
          </Typography>
        </Box>
        <Switch
          checked={value}
          onChange={handleChange}
          data-testid={`toggle-filter-${dataTestId}-switch`}
          inputProps={{ 'data-testid': `toggle-filter-${dataTestId}-input` }}
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: 'white!important',
            },
            '& .MuiSwitch-track': {
              backgroundColor: value ? '#497262!important' : '#B6BBB9!important',
              opacity: '1!important',
            },
          }}
        />
      </Box>
    </Box>
  );
};

ToggleFilter.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  titleTooltipMsg: PropTypes.string,
};

export default ToggleFilter;
