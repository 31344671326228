import PropTypes from 'prop-types';
import React from 'react';
import { useRouter } from 'next/router';

/* MUI */
import { Box } from '@mui/material';

/* Components */
import MultiSelectWithSearch from 'components/common/MultiSelectWithSearch';
import SingleSelectWithSearch from 'components/common/SingleSelectWithSearch';
import RangeFilter from 'components/common/RangeFilter';
import ToggleFilter from 'components/common/ToggleFilter';
import SelectedFilters from 'components/common/SelectedFilters';
import ScrollToTopButton from 'components/ScrollToTopButton';

/* Hooks */
import useIntersection from 'hooks/useIntersection';
import useResponsive from 'hooks/useResponsive';

/* Consts */
import { FILTER_TYPES, FILTER_MAP, ALLOWED_FILTERS_IN_STORE_PAGE } from 'constants/filter-types';
import { ALGOLIA_ATTRIBUTES } from 'constants';

const FilterColumn = ({ filters, selectedFilters, setFilter, clearFilter, clearAll }) => {
  const router = useRouter();
  const isMobile = useResponsive('down', 'md');
  const isStorePage = router.pathname.startsWith('/store');
  let filtersToRender = filters || [];
  const { targetRef: topRef, isIntersecting: isTopVisible } = useIntersection({
    rootMargin: isStorePage ? '100px' : '-235px', // Need positive rootMargin in Store Pages as they have store banner and description section
  });

  if (isStorePage) {
    filtersToRender = filters?.filter((filter) => ALLOWED_FILTERS_IN_STORE_PAGE.includes(filter?.name));
  }

  const appliedFilters = Object.entries(selectedFilters)
    ?.filter(([key]) => [...ALGOLIA_ATTRIBUTES, 'search-query'].includes(key))
    .map(([key, { label, value }]) => {
      const originalFilter = filters.length ? filters?.find((f) => f?.name === key) : null;

      return {
        key,
        label: originalFilter?.label && originalFilter?.label !== label ? originalFilter?.label : label,
        value,
      };
    });

  return (
    <Box>
      <SelectedFilters filters={appliedFilters} onRemove={clearFilter} onClearAll={clearAll} />
      <Box
        ref={topRef}
        sx={{
          '& > div': {
            borderBottom: '1px solid',
            borderColor: 'divider',
            '&:last-child': {
              borderBottom: 'none',
            },
          },
        }}
      >
        <ToggleFilter
          key="productIsOnOffer"
          title="On Offer"
          value={selectedFilters.productIsOnOffer?.value || false}
          setValue={(value) => setFilter('productIsOnOffer', value, 'On Offer')}
          dataTestId="filter-productIsOnOffer"
        />
        {filtersToRender?.map((filter) => {
          if (!FILTER_MAP[filter?.name]) return null;

          switch (filter?.type) {
            case FILTER_TYPES.RANGE:
              return (
                <RangeFilter
                  key={filter.name}
                  title={filter.label}
                  min={filter.data.min}
                  max={filter.data.max}
                  value={selectedFilters[filter.name]?.value || [filter.data.min, filter.data.max]}
                  setValue={(value) => setFilter(filter.name, value, filter.label)}
                  clear={() => clearFilter(filter.name)}
                  dataTestId={`filter-${filter.name}`}
                />
              );
            case FILTER_TYPES.MULTI_SELECT: {
              const multiSelectValues =
                typeof selectedFilters[filter.name]?.value === 'string'
                  ? [selectedFilters[filter.name]?.value]
                  : selectedFilters[filter.name]?.value || [];

              return (
                <MultiSelectWithSearch
                  key={filter.name}
                  title={filter.label}
                  options={filter.data.map((option) => ({
                    value: option.value,
                    label: option.label,
                    count: option.count,
                  }))}
                  values={multiSelectValues}
                  setValues={(values) => setFilter(filter.name, values, filter.label)}
                  clear={() => clearFilter(filter.name)}
                  dataTestId={`filter-${filter.name}`}
                  showSearchInput
                  searchInputPlaceholder={`Search ${filter.label}`}
                  showFilterCounter
                  defaultExpanded
                  hasSearch={filter?.hasSearch}
                  sort={filter?.sort}
                  showOptionCount={false}
                />
              );
            }
            case FILTER_TYPES.SINGLE_SELECT:
              return (
                <SingleSelectWithSearch
                  key={filter.name}
                  title={filter.label}
                  options={filter.data.map((option) => ({
                    value: option.value,
                    label: option.label,
                    count: option.count,
                  }))}
                  value={selectedFilters[filter.name]?.value || ''}
                  setValue={(value) => setFilter(filter.name, value, filter.label)}
                  clear={() => clearFilter(filter.name)}
                  dataTestId={`filter-${filter.name}`}
                  showSearchInput
                  searchInputPlaceholder={`Search ${filter.label}`}
                  defaultExpanded
                  hasSearch={filter?.hasSearch}
                  sort={filter?.sort}
                  showFilterCounter
                  showOptionCount={false}
                />
              );
            default:
              return null;
          }
        })}
      </Box>
      {!isMobile && <ScrollToTopButton isTopVisible={isTopVisible} />}
    </Box>
  );
};

FilterColumn.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      sort: PropTypes.bool,
      hasSearch: PropTypes.bool,
      maxValue: PropTypes.number,
      minValue: PropTypes.number,
      count: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          count: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  selectedFilters: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default FilterColumn;
