import Image from 'next/image';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';

// hooks
import useResponsive from 'hooks/useResponsive';
import useFilters from 'hooks/useFilters';

// constants
import { filterUrlQueryParams } from 'constants/category-filters';
import useFeatureFlags from 'hooks/useFeatureFlags';

const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '25px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}));

const CTA = styled(Button)(({ theme }) => ({
  padding: '5px 18px',
  border: '2px solid #333',
  color: theme.palette.primary.dark,
  fontSize: '20px',
  textTransform: 'initial',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    padding: '6px 10px',
  },
  ':disabled': {
    border: '2px solid #CCCCCC',
    cursor: 'not-allowed',
  },
}));

const noProductsMatchDefaultPropOnClearAll = () => {};

const NoProductsMatch = ({
  message = 'Sorry, we currently do not have products matching your selection',
  onClearAll = noProductsMatchDefaultPropOnClearAll,
}) => {
  const theme = useTheme();
  const { clearAllFilters, isFilterApplied, removeFilterFromStack, filtersStack } = useFilters();
  const isMobile = useResponsive('down', 'md');
  const router = useRouter();

  const { FACETED_FILTERS_ENABLED } = useFeatureFlags();

  const handleClearFiltersClick = () => {
    clearAllFilters();

    const { query } = router;
    const userQueries = { ...query };

    const filterQueriesSet = new Set(Object.values(filterUrlQueryParams));

    Object.keys(userQueries).forEach((q) => {
      if (filterQueriesSet.has(q)) {
        delete userQueries[q];
      }
    });

    router.query = userQueries;
    router.push(router);
  };

  const handleGoBackClick = () => {
    removeFilterFromStack();
  };

  return (
    <Container maxWidth="md" sx={{ my: theme.spacing(3.75) }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: isMobile ? '12px' : '30px',
        }}
      >
        <Image
          src="/assets/images/list/empty-list-emoji.png"
          height={isMobile ? 40 : 60}
          width={isMobile ? 40 : 60}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <Text variant="h5">{message}</Text>
        {!FACETED_FILTERS_ENABLED && (
          <Stack direction="row" gap="10px">
            <CTA disabled={!isFilterApplied} onClick={handleClearFiltersClick}>
              Clear all filters
            </CTA>
            <CTA disabled={filtersStack?.length <= 1} onClick={handleGoBackClick}>
              Remove Last Filter
            </CTA>
          </Stack>
        )}
        {FACETED_FILTERS_ENABLED && (
          <Stack direction="row" gap="10px">
            <CTA onClick={() => onClearAll()}>Clear all filters</CTA>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

NoProductsMatch.propTypes = {
  message: PropTypes.string,
  onClearAll: PropTypes.func,
};

export default NoProductsMatch;
