import React, { useState } from 'react';
import PropTypes from 'prop-types';

/** Hooks */
import useResponsive from 'hooks/useResponsive';
import useTopBar from 'hooks/useTopBar';

/** Components */
import Link from 'components/Link';

/** Material ui */
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

/** Utils */
import stringSimilarity from 'string-similarity-js';

const relevantCategoriesCollectionsDefaultPropSx = {};

const RelevantCategoriesCollections = ({ searchedText = '', sx = relevantCategoriesCollectionsDefaultPropSx }) => {
  const isTablet = useResponsive('down', 'md');
  const { collections, categories } = useTopBar();
  const [selectedCategory, setSelectedCategory] = useState(-1);

  const matchCategories = categories
    ?.filter((category) => stringSimilarity(category.name, searchedText) > 0.4)
    ?.map((category) => ({ ...category, type: 'category' }));
  const matchCollections = collections
    ?.filter((collection) => stringSimilarity(collection.name, searchedText) > 0.4)
    ?.map((collection) => ({ ...collection, type: 'collection' }));
  const matchesCategoriesAndCollections = [...matchCategories, ...matchCollections]
    .sort((a, b) => {
      const similarityA = stringSimilarity(a.name, searchedText);
      const similarityB = stringSimilarity(b.name, searchedText);
      return similarityB - similarityA;
    })
    .slice(0, 20);

  const handleCategoriesButtonHover = (index) => {
    setSelectedCategory(index);
  };

  const chipContainerStyle = !isTablet
    ? {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
      }
    : {
        display: 'flex',
        overflow: 'auto',
        pb: 0.25,
        mt: 0,
        '&::-webkit-scrollbar': {
          height: 0,
          width: 0,
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          display: 'none',
        },
      };

  return (
    <Grid container sx={{ ...sx }} data-testid="container-relevant-categories-and-collections">
      <Grid item xs={12} sx={chipContainerStyle} data-testid="container-relevant-categories-and-collections-chips">
        {matchesCategoriesAndCollections?.map((item, index) => (
          <Chip
            href={` ${item.type === 'category' ? `/product-category/${item.slug}` : `/collections/${item.slug}`}`}
            key={` ${item.type === 'category' ? `chip-category-${item.slug}` : `chip-collection-${item.slug}`}`}
            data-testid={` ${item.type === 'category' ? `chip-category-${item.slug}` : `chip-collection-${item.slug}`}`}
            onMouseEnter={() => handleCategoriesButtonHover(index)}
            onMouseLeave={() => setSelectedCategory(-1)}
            variant="outlined"
            component={Link}
            label={item.name}
            title={item.name}
            sx={{
              mx: 0.5,
              my: 0.25,
              '@media(max-width: 600px)': {
                ml: 0,
                mr: 0.5,
              },
              ...(selectedCategory === index && {
                backgroundColor: 'grey.100',
                cursor: 'pointer',
              }),
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

RelevantCategoriesCollections.propTypes = {
  sx: PropTypes.shape({}),
  searchedText: PropTypes.string,
};

export default RelevantCategoriesCollections;
