import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, Typography, Button, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const OverflowTooltipChip = ({ value, onDelete, dataTestId = '', deleteIcon = null }) => {
  const chipRef = useRef(null);
  const hiddenTextRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (chipRef?.current && hiddenTextRef?.current) {
        const chipWidth = chipRef.current?.offsetWidth;
        const textWidth = hiddenTextRef.current?.offsetWidth;

        setShowTooltip(textWidth > chipWidth);
      }
    };

    setTimeout(checkOverflow, 100);

    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [value]);

  return (
    <>
      <span ref={hiddenTextRef} style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap' }}>
        {value}
      </span>

      <Tooltip title={value} placement="top" arrow disableHoverListener={!showTooltip}>
        <Box ref={chipRef} sx={{ display: 'inline-block', maxWidth: '100%' }}>
          <Chip label={value} onDelete={onDelete} variant="outlined" data-testid={dataTestId} deleteIcon={deleteIcon} />
        </Box>
      </Tooltip>
    </>
  );
};

OverflowTooltipChip.propTypes = {
  value: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  deleteIcon: PropTypes.node,
};

const SelectedFilters = ({ filters, onRemove, onClearAll }) => {
  if (filters?.length === 0) return null;

  return (
    <Box sx={{ mt: 0.5, mb: 2, px: 0.5 }} data-testid="selected-filters-container">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="subtitle1" fontWeight="bold" data-testid="selected-filters-title">
          Selected filters
        </Typography>
        <Button
          variant="text"
          size="small"
          onClick={onClearAll}
          sx={{ textTransform: 'none' }}
          data-testid="selected-filters-clear-all"
        >
          Clear all
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} data-testid="selected-filters-chips">
        {filters?.map((filter) =>
          Array.isArray(filter?.value) ? (
            filter?.value?.map((item, index) => (
              <OverflowTooltipChip
                key={`${filter.key}-${item}`}
                value={item}
                onDelete={() => onRemove(filter.key, item)}
                dataTestId={`selected-filter-chip-${filter.key}-${index}`}
                deleteIcon={<CancelIcon data-testid={`selected-filter-chip-${filter.key}-${index}-delete-icon`} />}
              />
            ))
          ) : (
            <OverflowTooltipChip
              key={filter.key}
              value={typeof filter.value === 'boolean' ? filter.label : filter.value}
              onDelete={() => onRemove(filter.key)}
              dataTestId={`selected-filter-chip-${filter.key}`}
              deleteIcon={<CancelIcon data-testid={`selected-filter-chip-${filter.key}-delete-icon`} />}
            />
          )
        )}
      </Box>
    </Box>
  );
};

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
};

export default SelectedFilters;
