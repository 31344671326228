import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const VirgoListingSkeleton = ({ count }) => {
  return Array.from({ length: count }).map((_, index) => (
    <Box
      key={`skeleton-${index + 1}`}
      sx={{
        width: '100%',
        height: '300px',
        borderRadius: 1,
        overflow: 'hidden',
        mt: '16px',
        ml: '16px',
      }}
      data-testid={`virgo-listing-skeleton-container-${index + 1}`}
    >
      <Skeleton variant="rectangular" width="100%" height="100%" data-testid={`virgo-listing-skeleton-${index + 1}`} />
    </Box>
  ));
};

VirgoListingSkeleton.propTypes = {
  count: PropTypes.number.isRequired,
};

export default VirgoListingSkeleton;
