import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import NoProductsMatchMessage from 'components/Listing/Messages/NoProductsMatch';

const virgoListingEmptyDefaultPropOnClearAll = () => {};

const VirgoListingEmpty = ({ onClearAll = virgoListingEmptyDefaultPropOnClearAll }) => {
  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
      }}
      data-testid="virgo-listing-empty-container"
    >
      <NoProductsMatchMessage onClearAll={onClearAll} />
    </Box>
  );
};

VirgoListingEmpty.propTypes = {
  onClearAll: PropTypes.func,
};

export default VirgoListingEmpty;
