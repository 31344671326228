/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import useResponsive from 'hooks/useResponsive';

const LoadingSkeleton = ({ showSort = true }) => {
  const isMobile = useResponsive('down', 'md');

  return (
    <Grid container spacing={2}>
      {/* Filter Column - Only show on desktop */}
      {!isMobile && (
        <Grid item md={3} mt={1}>
          <Box sx={{ bgcolor: 'background.paper' }}>
            {[...Array(5)].map((_, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Box sx={{ width: '60%', mb: 1 }}>
                  <Skeleton height={24} />
                </Box>
                {[...Array(3)].map((__, i) => (
                  <Skeleton key={i} height={32} sx={{ mb: 1 }} />
                ))}
              </Box>
            ))}
          </Box>
        </Grid>
      )}

      {/* Content Column */}
      <Grid item xs={12} md={!isMobile ? 9 : 12}>
        {showSort && !isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Skeleton width={150} height={32} />
            <Skeleton width={90} height={32} />
          </Box>
        )}
        <Grid container spacing={2}>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Skeleton variant="rectangular" height={200} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

LoadingSkeleton.propTypes = {
  showSort: PropTypes.bool,
};

export default LoadingSkeleton;
