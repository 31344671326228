import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const VirgoListingGrid = ({ children }) => {
  return (
    <Grid
      sx={{
        '@media (max-width: 900px)': {
          justifyContent: 'space-evenly',
        },
      }}
      data-testid="virgo-listing-grid"
      container
      spacing={1}
      justifyContent="start"
    >
      {children}
    </Grid>
  );
};

VirgoListingGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VirgoListingGrid;
