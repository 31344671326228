import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';

/** Components */
import RelevantCategoriesCollections from 'components/Search/RelevantCategoriesCollections';
import { VirgoListingContainer } from 'components/Listing';
import FilterColumn from 'components/Listing/FilterColumn';
import LoadingSkeleton from 'components/Listing/LoadingSkeleton';
import SearchProductsBar from 'components/Header/SearchProductsBar';

/** Material-UI */
import { styled, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';

/** Providers */
import AddToCartPopoverProvider from 'provider/AddToCartPopoverProvider';

/** Hooks */
import useResponsive from 'hooks/useResponsive';
import { useScrollToRef } from 'hooks/useScrollToRef';
import { useQueryParamsState } from 'hooks/useQueryParamsState';
import useFiltersSearch from 'hooks/useFiltersSearch';

// constants
import { filterUrlQueryParams } from 'constants/category-filters';
import BrandCard from 'components/Brand/BrandCard';
import ProductCard from 'components/Product/ProductCard';

const NumberOfResults = ({ currentTab, totalProducts = 0, totalBrands = 0, isSearchPage = true, searchQuery = '' }) => {
  return (
    <Typography
      variant="h7"
      data-testid="lbl-results-for"
      key={`number-of-results-${currentTab === 'products' ? totalProducts : totalBrands}`}
    >
      <span data-testid="span-results-for-total">{currentTab === 'products' ? totalProducts : totalBrands} </span>
      {isSearchPage ? (
        <span data-testid="span-results-for">
          results for <strong>&quot;{searchQuery}&quot;</strong>
        </span>
      ) : (
        <span data-testid="span-results-for">results</span>
      )}
    </Typography>
  );
};

NumberOfResults.propTypes = {
  currentTab: PropTypes.oneOf(['products', 'brands']).isRequired,
  totalProducts: PropTypes.number,
  totalBrands: PropTypes.number,
  isSearchPage: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

const algoliaResultsLayoutDefaultPropInitialAlgoliaData = {
  filters: [],
  banners: [],
  products: [],
  totalProducts: 0,
  brands: [],
  totalBrands: 0,
  queryId: '',
};

const AlgoliaResultsLayout = ({
  searchQuery,
  reference,
  initialAlgoliaData = algoliaResultsLayoutDefaultPropInitialAlgoliaData,
}) => {
  const theme = useTheme();
  const router = useRouter();

  const [currentTab, setCurrentTab] = useState(
    ['products', 'brands'].includes(router.query?.tab) ? router.query?.tab : 'products'
  );
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const isMobile = useResponsive('down', 'md');
  const isSearchPage = reference === 'search';
  const isStorePage = reference === 'store';
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  // This const is only used to control the text inserted into the textbox on the store page
  const inputText = router.query['search-query'] || '';

  const [, setShouldScrollTo] = useScrollToRef();
  const [itemId, setItemId] = useQueryParamsState(filterUrlQueryParams.ITEM_ID);

  /* eslint-disable no-use-before-define */
  const resetSearchData = () => {
    searchData.resetPagination();
  };
  /* eslint-enable no-use-before-define */

  const prevSearchQueryRef = useRef();

  const { filters, banners, products, totalProducts, brands, totalBrands, queryId } = initialAlgoliaData || {};

  // When we are on the store page we have to use the value that is in the inputText
  const searchData = useFiltersSearch(
    isStorePage ? inputText : searchQuery,
    ['products', 'brands'].includes(currentTab) ? currentTab : 'products',
    'algoliasearch',
    reference,
    {
      filters,
      banners,
      products,
      totalProducts,
      brands,
      totalBrands,
      queryId,
    }
  );

  useEffect(() => {
    if (prevSearchQueryRef.current !== undefined && prevSearchQueryRef.current !== searchQuery) {
      searchData.clearAllFilters();
      resetSearchData();
    }
    prevSearchQueryRef.current = searchQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useLayoutEffect(() => {
    if (itemId && !searchData.isFirstLoading && !searchData.isLoadingMore) {
      setShouldScrollTo(true);
    }
  }, [itemId, searchData.isFirstLoading, searchData.isLoadingMore, setShouldScrollTo]);

  const handleItemIdChange = (newItemId, resetItemId = false) => {
    if (!newItemId && !resetItemId) return;
    setItemId(newItemId);
  };

  const handleTabChange = async (_, newValue) => {
    if (currentTab === newValue || !['products', 'brands'].includes(newValue)) return;

    setCurrentTab(newValue);
    await router.push(
      {
        ...router,
        query: {
          ...router.query,
          tab: newValue,
          limit: newValue === 'products' ? searchData.pagination.products.limit : searchData.pagination.brands.limit,
        },
      },
      undefined,
      { shallow: true }
    );
    handleItemIdChange('', true);
    searchData.updatePagination(newValue, searchData.pagination[newValue].limit, 0);
  };

  const handleSortChange = (event) => {
    searchData.setFilter('sortBy', event.target.value, 'Sort By');
  };

  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };

  const handleMobileSort = (value) => {
    searchData.setFilter('sortBy', value, 'Sort By');
    handleSortMenuClose();
  };

  const toggleMobileFilters = () => {
    setShowMobileFilters(!showMobileFilters);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'products':
        return (
          <AddToCartPopoverProvider>
            <VirgoListingContainer
              items={searchData.items.products}
              total={searchData.total.products}
              isFirstLoading={searchData.isFirstLoading}
              isLoadingMore={searchData.isLoadingMore}
              ItemComponent={ProductCard}
              onLoadMore={() => {
                searchData.loadMore(currentTab);
              }}
              itemName="product"
              resourceName="products"
              itemProps={{
                handleItemIdChange,
                algoliaQueryID: searchData.queryId,
                itemId,
              }}
              onClearAll={searchData.clearAllFilters}
            />
          </AddToCartPopoverProvider>
        );
      case 'brands':
        return (
          <VirgoListingContainer
            items={searchData.items.brands}
            total={searchData.total.brands}
            isFirstLoading={searchData.isFirstLoading}
            isLoadingMore={searchData.isLoadingMore}
            ItemComponent={BrandCard}
            onLoadMore={() => {
              searchData.loadMore(currentTab);
            }}
            resourceName="brands"
            itemName="brand"
            itemProps={{ handleItemIdChange, algoliaQueryID: searchData.queryId, itemId }}
            onClearAll={searchData.clearAllFilters}
          />
        );
      default:
        return null;
    }
  };

  if (searchData.isFirstLoading) {
    return (
      <Box
        sx={{
          py: 0.5,
          px: { xs: 0, md: 4, lg: 2, xl: 3 },
          [theme.breakpoints.down('md')]: {
            pt: 1,
          },
          padding: isMediumScreen ? '-0.5rem' : '0',
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography variant="h7" sx={{ mb: 0.5 }} data-testid="lbl-loading-results-for">
            {isSearchPage ? (
              <span data-testid="span-loading-results-for">
                Loading results for <strong>&quot;{searchQuery}&quot;</strong>
              </span>
            ) : (
              <span data-testid="span-loading-results-for">Loading results</span>
            )}
          </Typography>
        </Box>

        {/* The tabs only appear when we're not on the store page */}
        {!isStorePage && (
          <Grid container>
            <Grid item xs={12}>
              <Grid item md={3} pr={1.5}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '100%',
                    '& .MuiTabs-flexContainer': {
                      justifyContent: 'space-evenly',
                    },
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '1rem',
                      px: 2,
                      minWidth: 100,
                      flex: 1,
                    },
                    '& .MuiTabs-indicator': {
                      height: 3,
                    },
                  }}
                >
                  <Tab value="products" label="Products" data-testid="tab-products" />
                  <Tab value="brands" label="Brands" data-testid="tab-brands" />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
        )}

        <LoadingSkeleton showSort={!isMobile} />
      </Box>
    );
  }

  return (
    <Box
      data-testid="algolia-results-layout"
      sx={{
        mx: 'auto',
        width: '100% !important',
        '@media (min-width: 1590px)': {
          maxWidth: '1480px !important',
        },
        '@media (min-width: 1322px) and (max-width: 1590px)': {
          maxWidth: '1228px !important',
        },
        '@media (min-width: 1016px) and (max-width:1322px)': {
          maxWidth: '976px !important',
        },
        '@media (min-width: 900px) and (max-width:1016px)': {
          maxWidth: '724px !important',
        },
      }}
    >
      {isMobile && (
        <>
          {/* On the store page we have to render an input text */}
          {isStorePage && (
            <SearchProductsBar
              sx={{
                width: '400px',
                maxWidth: '100%',
                mb: 0.5,
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
              }}
            />
          )}
          <Grid
            container
            sx={{
              position: 'sticky',
              top: 98,
              zIndex: 2,
              backgroundColor: 'white',
              boxShadow: '0px 12px 24px -4px #919EAB29',
              height: '40px',
              mx: -1,
              width: 'auto',
            }}
          >
            <Grid item xs={6}>
              <Button
                onClick={handleSortMenuOpen}
                fullWidth
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  height: '100%',
                  color: 'text.primary',
                  fontSize: '14px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                Sort
              </Button>
            </Grid>
            <Grid item xs={6} borderLeft={1} borderColor="divider">
              <Button
                onClick={toggleMobileFilters}
                fullWidth
                endIcon={<TuneIcon />}
                sx={{
                  height: '100%',
                  color: 'text.primary',
                  fontSize: '14px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                Filter
              </Button>
            </Grid>
          </Grid>

          <Menu
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={handleSortMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              mt: '-1px',
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '200px',
                boxShadow: '0px 12px 24px -4px #919EAB29',
              },
              '& .MuiMenuItem-root': {
                fontSize: '14px',
                py: 1.5,
              },
            }}
          >
            <MenuItem
              onClick={() => handleMobileSort('Recommended')}
              selected={searchData.selectedFilters?.sortBy === 'Recommended'}
            >
              Recommended
            </MenuItem>
            <MenuItem
              onClick={() => handleMobileSort('Latest')}
              selected={searchData.selectedFilters?.sortBy === 'Latest'}
            >
              Latest
            </MenuItem>
          </Menu>
          <Drawer
            anchor="right"
            open={showMobileFilters}
            onClose={toggleMobileFilters}
            sx={{
              '& .MuiDrawer-paper': {
                width: '85%',
                py: 1,
                overflowY: 'auto',
              },
            }}
          >
            <CloseIconWrapper>
              <IconButton onClick={toggleMobileFilters} sx={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon sx={{ fill: 'white', width: '100%', height: '100%' }} />
              </IconButton>
            </CloseIconWrapper>
            <Box
              sx={{
                overflowY: 'auto',
                width: '100%',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <FilterColumn
                filters={searchData.filters}
                selectedFilters={searchData.selectedFilters}
                setFilter={searchData.setFilter}
                clearFilter={searchData.clearFilter}
                clearAll={searchData.clearAllFilters}
              />
            </Box>
            <Box sx={{ px: 1, mt: 'auto', pt: 2, width: '100%' }}>
              <Button variant="contained" fullWidth onClick={toggleMobileFilters}>
                See {currentTab === 'brands' ? 'Brands' : 'Products'}
              </Button>
            </Box>
          </Drawer>
        </>
      )}

      <Box sx={{ mb: 0.5 }} />

      {/* Products and Brands Tab- The tabs only appear when we're not on the store page */}
      {!isStorePage && (
        <Box
          sx={{
            width: '220px',
            '@media (max-width: 899px)': {
              width: '100%',
            },
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-evenly',
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '1rem',
                px: 2,
                minWidth: 100,
                flex: 1,
              },
              '& .MuiTabs-indicator': {
                height: 3,
                backgroundColor: '#497262',
              },
            }}
          >
            <Tab value="products" label="Products" data-testid="tab-products" />
            <Tab value="brands" label="Brands" data-testid="tab-brands" />
          </Tabs>
        </Box>
      )}

      <Stack gap="16px" width="100%" data-testid="main-container" direction="row">
        {!isMobile && (
          <Stack
            direction="column"
            sx={{
              minWidth: '220px !important',
              maxWidth: '220px !important',
            }}
            data-testid="filters-column"
          >
            <FilterColumn
              filters={searchData.filters}
              selectedFilters={searchData.selectedFilters}
              setFilter={searchData.setFilter}
              clearFilter={searchData.clearFilter}
              clearAll={searchData.clearAllFilters}
            />
          </Stack>
        )}

        <Stack
          sx={{
            gap: '10px',
            width: '100%',
            '@media (max-width: 899px)': {
              marginTop: isStorePage ? '16px' : '10px',
            },
          }}
          data-testid="search-result-items-parent"
        >
          {/* On the store page we didn't give the total number of results */}
          {!isStorePage && isMobile && (
            <NumberOfResults
              currentTab={currentTab}
              isSearchPage={isSearchPage}
              searchQuery={searchQuery}
              totalBrands={searchData?.total?.brands}
              totalProducts={searchData?.total?.products}
            />
          )}

          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 0.5,
                alignItems: 'center',
              }}
            >
              {/* On the store page we have to render an input text */}
              {/* On the store page we didn't give the total number of results */}
              {isStorePage ? (
                <SearchProductsBar
                  sx={{
                    maxWidth: '40%',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <NumberOfResults
                  currentTab={currentTab}
                  isSearchPage={isSearchPage}
                  searchQuery={searchQuery}
                  totalBrands={searchData?.total?.brands}
                  totalProducts={searchData?.total?.products}
                />
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Sort by:
                </Typography>
                <Select
                  value={searchData.selectedFilters?.sortBy?.value || 'Recommended'}
                  onChange={handleSortChange}
                  size="small"
                  sx={{
                    minWidth: 150,
                    '& .MuiSelect-select': {
                      py: 0.5,
                    },
                  }}
                >
                  <MenuItem value="Recommended">Recommended</MenuItem>
                  <MenuItem value="Latest">Latest</MenuItem>
                </Select>
              </Box>
            </Box>
          )}
          <Box sx={{ mb: 1, justifySelf: 'end' }} data-testd="search-result-items">
            {renderContent()}
          </Box>
        </Stack>
      </Stack>

      {isSearchPage && <RelevantCategoriesCollections searchedText={searchQuery} />}
    </Box>
  );
};

AlgoliaResultsLayout.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  reference: PropTypes.oneOf(['search', 'product-category', 'store']).isRequired,
  initialAlgoliaData: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    banners: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
    brands: PropTypes.arrayOf(PropTypes.shape({})),
    totalBrands: PropTypes.number,
    queryId: PropTypes.string,
  }),
};

export default AlgoliaResultsLayout;

const CloseIconWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: '0',
  top: theme.spacing(0.375),
  zIndex: 1300,
  width: '15vw',
}));
