import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook to observe the intersection of an element with the viewport.
 *
 * @param {IntersectionObserverInit} [options={}] - Options for the IntersectionObserver.
 * @returns {{ targetRef: React.RefObject<HTMLElement>, isIntersecting: boolean }}
 * An object containing the targetRef to be observed and a boolean indicating if it is intersecting.
 */
export default function useIntersection(options = {}) {
  const targetRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!targetRef.current) return () => {};

    const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), options);

    observer.observe(targetRef.current);

    return () => observer.disconnect();
  }, [options]);
  return { targetRef, isIntersecting };
}
