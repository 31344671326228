import { useReducer, useCallback, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { bffFetcher, generateAlgoliaAnalyticsTags } from 'utils/functions';
import { DEFAULT_PRODUCTS_LIMIT, DEFAULT_BRANDS_LIMIT, SORT_BY, DEFAULT_LIMITS } from 'constants/listing-defaults';
import useAuth from './useAuth';
import useFeatureFlags from './useFeatureFlags';

const ALGOLIA_ATTRIBUTES = [
  'productIsOnOffer',
  'productCategoryName',
  'productWholesalerCountryId',
  'productWholesalerName',
  'productWholesalerBrandValues',
  'leadTime',
  'productWholesalerStoreMinOrderAmountGbp',
  'flairs',
  'productCollectionName',
];

const DEFAULT_PAGINATION = {
  products: { limit: DEFAULT_PRODUCTS_LIMIT, offset: 0 },
  brands: { limit: DEFAULT_BRANDS_LIMIT, offset: 0 },
};

const initialState = ({ initialAlgoliaData, initialPagination, initialSelectedFilters = {} }) => {
  return {
    banners: initialAlgoliaData.banners || [],
    queryId: initialAlgoliaData.queryId || null,
    filters: initialAlgoliaData.filters || {},
    total: {
      products: initialAlgoliaData.totalProducts || 0,
      brands: initialAlgoliaData.totalBrands || 0,
    },
    items: {
      products: initialAlgoliaData.products || [],
      brands: initialAlgoliaData.brands || [],
    },
    isLoadingMore: false,
    isFirstLoading: true,
    error: null,
    selectedFilters: { ...initialSelectedFilters },
    pagination: {
      ...initialPagination,
    },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER': {
      const { key, value, label } = action.payload.filter;
      const newFilters = { ...state.selectedFilters };
      if (!value || (Array.isArray(value) && value.length === 0)) {
        delete newFilters[key];
      } else {
        newFilters[key] = { value, label };
      }
      return { ...state, selectedFilters: newFilters, pagination: action.payload.pagination };
    }
    case 'SET_FILTERS_FROM_URL':
      return { ...state, selectedFilters: { ...action.payload.selectedFilters } };
    case 'CLEAR_FILTER': {
      const { key, pagination } = action.payload;
      const newSelectedFilters = { ...state.selectedFilters };
      delete newSelectedFilters[key];

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        pagination,
      };
    }
    case 'CLEAR_ALL_FILTERS':
      return {
        ...state,
        selectedFilters: {},
        pagination: DEFAULT_PAGINATION,
      };
    case 'SET_DATA':
      return {
        ...state,
        items: {
          ...state.items,
          [action.resource]: action.isReset
            ? action.payload.items
            : [...state.items[action.resource], ...action.payload.items],
        },
        total: { ...state.total, [action.resource]: action.payload.total },
        banners: action.payload.banners || [],
        queryId: action.payload.queryId || null,
        filters: action.payload.filters || [],
        isLoadingMore: false,
        isFirstLoading: false,
      };
    case 'SET_LOADING_MORE':
      return { ...state, isLoadingMore: action.payload };
    case 'SET_FIRST_LOADING':
      return { ...state, isFirstLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, isLoadingMore: false, isFirstLoading: false, error: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: { ...state.pagination, ...action.payload } };
    case 'SET_PAGINATION_AND_STATE':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload.pagination },
        selectedFilters: { ...action.payload.selectedFilters },
      };
    case 'RESET_PAGINATION':
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
      };
    default:
      return state;
  }
};

const clearFilterParams = (query) => {
  const newQuery = { ...query };
  Object.keys(newQuery).forEach((key) => {
    if ([...ALGOLIA_ATTRIBUTES, 'search-query', 'sortBy'].includes(key)) {
      delete newQuery[key];
    }
  });
  return newQuery;
};

const updateUrlWithFilters = async (router, filters, pagination = {}) => {
  const isSearchPage = router.pathname === '/search';
  let newQuery = { ...router.query };

  if (isSearchPage) {
    delete newQuery?.slug;
  }

  newQuery = clearFilterParams(newQuery);

  // Add current filters to query
  Object.entries(filters).forEach(([key, { value }]) => {
    if (!value) return;
    if (Array.isArray(value)) {
      newQuery[key] = encodeURIComponent(value.join(','));
    } else {
      newQuery[key] = encodeURIComponent(value.toString());
    }
  });

  if (pagination?.limit) {
    newQuery.limit = pagination.limit;
  }

  await router.push(
    {
      pathname: router.pathname,
      query: newQuery,
    },
    undefined,
    { shallow: true }
  );
};

const parseUrlParams = (query) => {
  const selectedFilters = {};
  const currentTab = ['products', 'brands'].includes(query?.tab) ? query?.tab : 'products';
  const pagination = {
    products: { limit: (currentTab === 'products' && Number(query.limit)) || DEFAULT_PRODUCTS_LIMIT },
    brands: { limit: (currentTab === 'brands' && Number(query.limit)) || DEFAULT_BRANDS_LIMIT },
  };

  Object.entries(query).forEach(([key, value]) => {
    // Skip non-filter query params
    if (['q', 'tab', 'limit'].includes(key)) return;

    if (![...ALGOLIA_ATTRIBUTES, 'search-query', 'sortBy'].includes(key)) return;

    // decode the value before processing
    const decodedValue = decodeURIComponent(value);

    // Handle array values (comma-separated in URL)
    if (typeof decodedValue === 'string' && decodedValue.includes(',')) {
      selectedFilters[key] = { value: decodedValue.split(','), label: key };
    }
    // Handle boolean values
    else if (decodedValue === 'true' || decodedValue === 'false') {
      selectedFilters[key] = { value: decodedValue === 'true', label: key };
    }
    // Handle regular string values and a filter must always have a value
    else if (decodedValue) {
      selectedFilters[key] = { value: decodedValue, label: key };
    }
  });

  return { selectedFilters, pagination: { [currentTab]: pagination[currentTab] } };
};

const useFiltersSearch = (searchQuery, asset, type, reference, initialAlgoliaData) => {
  const router = useRouter();
  const { user } = useAuth();
  const { SEARCH_RESULTS_ALGOLIA } = useFeatureFlags();
  const isSyncingFromUrl = useRef(false);
  const DEBOUNCE_DELAY = 25;
  const { query } = router;
  const { slug } = router.query;
  const shippingCountryCode = user?.shippingCountry;
  const [state, dispatch] = useReducer(
    reducer,
    initialState({
      initialAlgoliaData,
      initialPagination: {
        products: { limit: (asset === 'products' && Number(query.limit)) || DEFAULT_PRODUCTS_LIMIT, offset: 0 },
        brands: { limit: (asset === 'brands' && Number(query.limit)) || DEFAULT_BRANDS_LIMIT, offset: 0 },
      },
      initialSelectedFilters: parseUrlParams(query).selectedFilters,
    })
  );

  const { isFirstLoading, isLoadingMore, pagination, items, banners, queryId, total, error, filters, selectedFilters } =
    state;
  let searchType = type;
  if (type === 'full' && SEARCH_RESULTS_ALGOLIA) {
    searchType = 'algolia';
  }

  const resetPagination = () => {
    dispatch({
      type: 'RESET_PAGINATION',
    });
  };

  const updatePagination = (resource, limit, offset = 0) => {
    dispatch({
      type: 'SET_PAGINATION',
      payload: {
        [resource]: {
          limit,
          offset,
        },
      },
    });
  };

  const loadMore = async (resource) => {
    if (isLoadingMore) return;

    const newLimit = pagination[resource].limit + DEFAULT_LIMITS[resource];
    const nextOffset = items[resource].length ?? 0;

    isSyncingFromUrl.current = true;
    await router.push(
      {
        ...router,
        query: { ...router.query, limit: newLimit },
      },
      undefined,
      { shallow: true }
    );

    updatePagination(resource, newLimit, nextOffset);
    setTimeout(() => {
      isSyncingFromUrl.current = false;
    }, 100);
  };

  const setFilter = useCallback(
    (key, value, label) => {
      dispatch({
        type: 'SET_FILTER',
        payload: {
          filter: { key, value, label },
          pagination: DEFAULT_PAGINATION,
        },
      });
    },
    [selectedFilters]
  );

  const clearAllFilters = useCallback(async () => {
    const { ...restQuery } = router.query;
    dispatch({ type: 'CLEAR_ALL_FILTERS' });
    const newQuery = clearFilterParams({ ...restQuery });
    await router.push(
      {
        pathname: router.pathname,
        query: {
          slug,
          ...newQuery,
        },
      },
      undefined,
      { shallow: true }
    );
  }, [selectedFilters]);

  const clearFilter = useCallback(
    (key, itemToRemove) => {
      const currentFilter = selectedFilters[key];
      if (!currentFilter) return;

      if (Array.isArray(currentFilter.value)) {
        const newValue = itemToRemove ? currentFilter.value.filter((item) => item !== itemToRemove) : [];

        dispatch({
          type: 'SET_FILTER',
          payload: {
            filter: { key, value: newValue, label: currentFilter.label },
            pagination: DEFAULT_PAGINATION,
          },
        });

        if (newValue.length === 0) {
          dispatch({
            type: 'CLEAR_FILTER',
            payload: {
              key,
              pagination: DEFAULT_PAGINATION,
            },
          });
        }
      } else {
        dispatch({
          type: 'CLEAR_FILTER',
          payload: {
            key,
            pagination: DEFAULT_PAGINATION,
          },
        });
      }
    },
    [selectedFilters]
  );

  const queryParams = useMemo(() => {
    return new URLSearchParams({
      ...(reference === 'search' && { q: searchQuery }),
      ...(reference === 'product-category' && { productCategorySlug: slug, q: '' }),
      ...(reference === 'store' && { productWholesalerSlug: slug, q: searchQuery }),
      type: searchType,
      sortBy: query.sortBy || SORT_BY.RECOMMENDED,
      limit: state.pagination[asset].limit,
      resource: asset,
      offset: state.pagination[asset].offset || 0,
      ...(shippingCountryCode && { shippingCountryCode }),
      ...(SEARCH_RESULTS_ALGOLIA ? { analyticsTags: generateAlgoliaAnalyticsTags(reference, user).join(',') } : {}),
      ...Object.entries(state.selectedFilters).reduce((acc, [key, obj]) => {
        return { ...acc, [key]: Array.isArray(obj.value) ? obj.value.join(',') : obj.value };
      }, {}),
    });
  }, [
    searchQuery,
    asset,
    searchType,
    reference,
    query,
    state.pagination,
    state.selectedFilters,
    shippingCountryCode,
    SEARCH_RESULTS_ALGOLIA,
    user,
    slug,
  ]);

  const fetchData = useCallback(
    async (resource) => {
      if (state.isLoadingMore) return;
      dispatch({ type: 'SET_LOADING_MORE', payload: true });
      try {
        const response = await bffFetcher(`/search?${queryParams.toString()}`);
        dispatch({
          type: 'SET_DATA',
          resource,
          payload: {
            items: response[resource],
            total: response.total || 0,
            banners: response.banners,
            queryId: response.queryID,
            filters: response.filters,
          },
          isReset: state.pagination[resource].offset === 0,
        });
      } catch (err) {
        dispatch({ type: 'SET_ERROR', payload: err });
      }
    },
    [queryParams]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isFirstLoading) {
        if (reference === 'search') {
          fetchData(asset);
          return;
        }

        dispatch({ type: 'SET_FIRST_LOADING', payload: false });
        return;
      }
      fetchData(asset);

      isSyncingFromUrl.current = true;
      updateUrlWithFilters(router, state.selectedFilters, state.pagination[asset]);

      setTimeout(() => {
        isSyncingFromUrl.current = false;
      }, 100);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilters, pagination]);

  const normalizeFilters = (filtersData) => {
    return Object.fromEntries(Object.entries(filtersData).map(([key, { value }]) => [key, value]));
  };

  useEffect(() => {
    if (!isLoadingMore) {
      if (isSyncingFromUrl.current) {
        isSyncingFromUrl.current = false;
        return;
      }

      const urlState = parseUrlParams(router.query);
      const currentState = {
        selectedFilters: normalizeFilters(state.selectedFilters),
        pagination: { [asset]: { limit: state.pagination[asset].limit } },
      };
      const isDifferent =
        JSON.stringify({ ...urlState, selectedFilters: normalizeFilters(urlState.selectedFilters) }) !==
        JSON.stringify(currentState);

      if (isDifferent) {
        dispatch({
          type: 'SET_PAGINATION_AND_STATE',
          payload: {
            pagination: {
              [asset]: { limit: urlState.pagination[asset]?.limit || DEFAULT_LIMITS[asset], offset: 0 },
            },
            selectedFilters: urlState.selectedFilters,
          },
        });
        updateUrlWithFilters(router, urlState.selectedFilters);
      }
    }
  }, [router.query]);

  return {
    items,
    banners,
    queryId,
    total,
    isFirstLoading,
    isLoadingMore,
    loadMore,
    resetPagination,
    updatePagination,
    error,
    filters,
    pagination,
    selectedFilters,
    setFilter,
    clearAllFilters,
    clearFilter,
  };
};

export default useFiltersSearch;
