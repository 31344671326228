import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import LoadMore from 'components/LoadMore';
import useResponsive from 'hooks/useResponsive';
import VirgoListingSkeleton from './VirgoListingSkeleton';
import VirgoListingEmpty from './VirgoListingEmpty';
import VirgoListingGrid from './VirgoListingGrid';

const VirgoListingContainer = ({
  items,
  total,
  isFirstLoading,
  isLoadingMore,
  ItemComponent,
  onLoadMore,
  resourceName,
  itemName,
  onClearAll,
  itemProps = {},
}) => {
  const showSkeleton = isFirstLoading || (isLoadingMore && !items?.length);
  const showEmpty = !isLoadingMore && !items?.length;
  const showLoadMore = items?.length > 0 && items?.length < total;
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ width: '100%' }} data-testid="virgo-listing-grid-parent">
      <VirgoListingGrid>
        {showSkeleton && <VirgoListingSkeleton count={8} />}

        {items?.map((item, index) => (
          <Grid
            sx={
              isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                  }
                : {}
            }
            item
            xs={6}
            sm="auto"
            key={item.id}
            data-scroll-to-element={item.id === itemProps.itemId ? 'scroll-reference-id' : null}
          >
            <ItemComponent {...itemProps} {...{ [itemName]: item }} index={index} />
          </Grid>
        ))}

        {isLoadingMore && <VirgoListingSkeleton count={4} />}
      </VirgoListingGrid>

      {showEmpty && <VirgoListingEmpty onClearAll={onClearAll} />}

      {showLoadMore && (
        <Box sx={{ mt: 3, mb: 1.5, display: 'flex', justifyContent: 'center' }}>
          <LoadMore
            total={total}
            viewed={items?.length}
            resource={resourceName}
            isLoading={isLoadingMore}
            onClickHandler={onLoadMore}
            progress={(items?.length ?? 0 / total) * 100}
          />
        </Box>
      )}
    </Box>
  );
};

VirgoListingContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  ItemComponent: PropTypes.elementType.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  itemProps: PropTypes.shape({
    handleItemIdChange: PropTypes.func.isRequired,
    algoliaQueryID: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
  }).isRequired,
  itemName: PropTypes.string.isRequired,
  isFirstLoading: PropTypes.bool.isRequired,
};

export default VirgoListingContainer;
